import React from 'react'
import { inheritClassNames } from '../lib/helpers'

const HeadlineColumn = props => {
  return (
    <div className={`column headline-column text-caps ${inheritClassNames(props)}`}>
          {props.title || props.children}
    </div>
  )
}

export default HeadlineColumn
